<template>
  <form class="change-nickname" @submit.prevent="handleSubmit">
    <h2 class="title">{{ t('nicknameChange.userName') }}</h2>
    <p class="caption">{{ t('nicknameChange.enterUniqueNickname') }}</p>
    <StInput
      v-model="nickname"
      class="nickname-input"
      data-t="nickname-input"
      :error-message="errorMessage"
      :error="!!errorMessage"
      :placeholder="t('nicknameChange.nicknamePlaceholder')"
    />
    <StButton
      submit
      :label="t('nicknameChange.save')"
      class="submit-button"
      data-t="submit-button"
      :loading="isLoading"
      size="xl"
    />
    <NuxtI18nLink :to="{ query: {} }" replace submit class="close-button">
      {{ t('nicknameChange.cancel') }}
    </NuxtI18nLink>
  </form>
</template>

<script setup lang="ts">
import { useChangeNicknameForm } from './useChangeNicknameForm'

const { t } = useI18n()
const { nickname, handleSubmit, errorMessage, isLoading } =
  useChangeNicknameForm()
</script>

<style scoped>
.change-nickname {
  display: flex;
  flex-direction: column;
  width: 480px;
  padding: var(--spacing-500) var(--spacing-600);
}

.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.caption {
  margin: var(--spacing-100) 0 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.nickname-input {
  margin-top: var(--spacing-300);
}

.submit-button {
  margin-top: var(--spacing-300);
}

.close-button {
  margin-top: var(--spacing-200);

  font: var(--desktop-text-md-semibold);
  color: var(--text-tertiary);
  text-align: center;
  text-decoration: none;

  &:hover {
    color: var(--text-primary);
  }

  &:active {
    color: var(--text-secondary);
  }
}
</style>
